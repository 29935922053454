<template>
  <div id="absolute-page">
    <TitleHelper :title="'Analyse Absolute Radio Playlist'"></TitleHelper>
    <div class="display">
        <div class="search-options">
            <div class="detail-display">
                <div>Data from: {{dateArrange(earliest)}} to {{dateArrange(latest)}}</div>
                <div>{{summary}}</div>
            </div>
            <div class="weekday-picker">
                <div v-for="day in this.days" :key="'day-'+day.value">
                    <input type = "checkbox" :id="day.id" :value="day.value" v-model="dayOfWeek" @change="dayChange">
                    <label :for="day.id"> {{day.id}}</label>
                </div>
            </div>
            <div class="hours-picker">
                <div>
                    <span class="hours-text">From:</span>
                    <select v-model="startTime" @change="startChange">
                        <option value=""></option>
                        <option v-for='t in this.timeOptions' :key="'time-start-'+t">{{t}}</option>
                    </select>
                </div>
                <div v-if="startTime != 23">
                    <span class="hours-text">To:</span>
                    <select v-model="endTime" @change="endChange">
                        <option value=""></option>
                        <option v-for='t in this.endOptions' :key="'time-start-'+t">{{t}}</option>
                    </select>
                </div>
            </div>
            <div class="since-picker">
                <span class="hours-text">Period:</span>
                <select v-model="since" @change="sinceChange" id="since-select">
                    <option v-for='(t,n) in this.sinceOptions' :value="t.value" :key="'time-start-'+n">{{t.desc}}</option>
                </select>
                <div>
                    <input type="radio" v-model="bandsOnly" id="songs" value="false" @change="bandsOnlyChange">
                    <label for="songs">Songs</label>
                </div>
                <div>
                    <input type="radio" v-model="bandsOnly" id="bands" value="true" @change="bandsOnlyChange">
                    <label for="bands">Bands</label>
                </div>
            </div>
        </div>
        <div class="response" @scroll="onScroll" id="response-zone">
            <div v-for="(d,n) in data" :key="'song-'+n" class="item">
                <div class="count">
                    {{d.count}}
                </div>
                <div class="record" v-if="bandsOnly == 'false'">
                    <div class="song">{{d._id.song}}</div>
                    <div class="act">{{d._id.act}}</div>
                </div>
                <div class="record" v-if="bandsOnly == 'true'">
                    <div class="song">{{d._id}}</div>
                    <div class="act" v-if="d.songs">from {{d.songs}} {{d.songs > 1 ? 'different songs' : 'song'}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config")
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "absoluteRadio",
  beforeCreate: function() {
    document.body.className = 'absolute-radio';
  },
  data() {
    return {
        values: config,
        queryElements: {},
        queryConstructor: "",
        data : null,
        dayOfWeek: [],
        bandsOnly : "false",
        days: [
            {'id': 'Monday', 'value': 2},
            {'id': 'Tuesday', 'value': 3},
            {'id': 'Wednesday', 'value': 4},
            {'id': 'Thursday', 'value': 5},
            {'id': 'Friday', 'value': 6},
            {'id': 'Saturday', 'value': 7},
            {'id': 'Sunday', 'value': 1},
        ],
        daysLookup: {
            1: 'Sunday',
            2: 'Monday',
            3: 'Tuesday',
            4: 'Wednesday',
            5: 'Thursday',
            6: 'Friday',
            7: 'Saturday',
        },
        timeOptions: [],
        endOptions: [],
        startTime: null,
        endTime: null,
        sinceOptions: [],
        since: null,
        summary: '',
        earliest: null,
        latest: null,
        displayed: 50,
        offset: 0,
        scrollable: true,
        loading: false,
    };
  },
  mounted() {
      for (let a=0; a<24; a++) {
          this.timeOptions.push(a);
          this.endOptions.push(a);
      }
      let d = new Date();
      d.setDate(d.getDate() - 1);
      d.setHours(23,59,59);
      this.sinceOptions.push({'desc': 'last week', 'value': d.getTime() - (7*24*60*60*1000)});
      this.sinceOptions.push({'desc': 'last 30 days', 'value': d.getTime() - (30*24*60*60*1000)});
      this.sinceOptions.push({'desc': 'last 60 days', 'value': d.getTime() - (60*24*60*60*1000)});
      this.sinceOptions.push({'desc': 'last 90 days', 'value': d.getTime() - (90*24*60*60*1000)});
      this.sinceOptions.push({'desc': 'ever', 'value': null});
      this.getFirst();
      this.getLast();
      this.queryMaker();
  },
  components: {
    TitleHelper,
  },
  methods: {
      onScroll: function({ target: { scrollTop, clientHeight, scrollHeight }}) {
        if (scrollTop + clientHeight >= scrollHeight && this.data && this.scrollable && !this.loading) {
          this.offset += 50;
          this.queryMaker();
        }
      },
      scrollToTop: function() {
          let el = document.getElementById('response-zone');
          el.scrollTop=0;
      },
      dayChange: function() {
          let t = this.dayOfWeek.toString();
          if (t) {
              this.queryElements['days'] = t;
          } else {
              this.queryElements['days'] = "";
          }
          this.offset = 0;
          this.queryMaker();
      },
      queryMaker: function() {
          this.loading = true;
          this.queryElements['offset'] = this.offset;
          this.queryConstructor = "?";
          for (const [k,v] of Object.entries(this.queryElements)) {
              if ((v && v != "false") || k == "offset") {
                    if (this.queryConstructor.indexOf('=') == -1) {
                        this.queryConstructor += k + '=' + v;
                    } else {
                        this.queryConstructor += '&' + k + '=' + v;
                    }
              }
          }
          if (this.queryConstructor == "?") {
              this.queryConstructor = "";
          }
          this.summary = '';
          if ('from' in this.queryElements && this.queryElements['from'] != '') {
              this.summary += 'played after ' + this.queryElements['from'] + ':00, '
          }
          if ('to' in this.queryElements && this.queryElements['to'] != '') {
              this.summary += 'played before ' + this.queryElements['to'] + ':00, '
          }
          if ('days' in this.queryElements && this.queryElements['days'].length > 0) {
              this.summary += 'played on ';
              for (let d of this.queryElements['days']) {
                  if (d != ',') {
                    if (this.queryElements['days'].length > 1 && d == this.queryElements['days'][this.queryElements['days'].length-1]) {
                        this.summary = this.summary.substring(0, this.summary.length - 2);
                        this.summary += ' or '
                    }
                    this.summary += this.daysLookup[d] + ', ';
                  }
              }
          }
          if (this.summary.length == 0) {
              this.summary = "played ";
          }
          let sel = document.getElementById('since-select');
          let tex = 'ever';
          if (sel.options[sel.selectedIndex]) {
            tex = sel.options[sel.selectedIndex].text;
            if (tex != 'ever') {
                this.summary += "in "
            }
          }
          this.summary += tex;
          this.getData();
      },
      startChange: function() {
          this.endOptions = [];
          for (let a=0; a<24; a++) {
              if (!this.startTime || (this.startTime && a > this.startTime)) {
                  this.endOptions.push(a);
              }
          } 
          if (parseInt(this.startTime) > parseInt(this.endTime)) {
              this.endTime = null;
          }
          this.queryElements['from'] = this.startTime;
          this.offset = 0;
          this.queryMaker();
      },
      endChange: function() {
          this.queryElements['to'] = this.endTime;
          this.offset = 0;
          this.queryMaker();
      },
      sinceChange: function() {
          this.queryElements['since'] = this.since;
          this.offset = 0;
          this.queryMaker();
      },
      bandsOnlyChange: function() {
          this.data = null;
          this.queryElements['bands'] = this.bandsOnly;
          this.offset = 0;
          this.queryMaker();
      },
      getData: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/absolute" + this.queryConstructor;
        axios
            .get(baseUrl,body,config)
            .then(response => {
                if (response.data.length < 50) {
                    this.scrollable = false;
                } else {
                    this.scrollable = true;
                }
                if (this.offset > 0) {
                    for (let r = 0; r < response.data.length; r++) {
                        this.data.push(response.data[r]);
                        this.loading = false;
                    }
                } else {
                    this.data = response.data;
                    this.loading = false;
                    this.scrollToTop();
                }
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      getFirst: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/absolute/earliest";
        axios
            .get(baseUrl,body,config)
            .then(response => {
                this.earliest = response.data[0]['played'];
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      getLast: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/absolute/latest";
        axios
            .get(baseUrl,body,config)
            .then(response => {
                this.latest = response.data[0]['played'];
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      dateArrange: function(d) {
          if (d) {
            d = new Date(d);
            let dArray = [];
            dArray.push(d.getUTCDate().toString());
            dArray.push((d.getUTCMonth()+1).toString());
            dArray.push(d.getUTCFullYear().toString());
            dArray.push(d.getUTCHours().toString());
            dArray.push(d.getUTCMinutes().toString());
            for (let i = 0; i < dArray.length; i++) {
                if (dArray[i].length < 2) {
                    dArray[i] = '0'+dArray[i];
                }
            }
            return dArray[0]+"/"+dArray[1]+"/"+dArray[2]+" "+dArray[3]+":"+dArray[4];
          } else {
            return null;
          }
      }
  },
  computed: {
  },
  watch: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@font-face {
    font-family: 'absolute-font';
    src: url('./fonts/GothamPro-Bold.woff2') format('woff2'),
         url('./fonts/GothamPro-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


#absolute-page {
  font-size: 14px;
  min-height: calc(100vh);
  background-color: grey;
  font-family: "absolute-font", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

div.search-options {
    background-color: purple;
    padding: 10px;
    display: flex;
    position: relative;
}

div.weekday-picker,
div.hours-picker,
div.since-picker {
    text-align: left;
    width: 33%;
    color: white;
}

div.detail-display {
    font-size: 10px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    text-align: right;
    color: lightgrey;
    width: 60%;
}

span.hours-text {
    width: 65px;
    float: left;
}

select {
    position: relative;
    bottom: 3px; 
}

.item {
    display: flex;
    text-align: left;
    padding: 6px 0;
}

.item .count {
    font-size: 50px;
    min-width: 100px;
    text-align: center;
}

.item .song {
    color: purple;
    font-size: 30px;
}

.response {
    overflow: scroll;
    height: calc(100vh - 153px);
    scroll-behavior: smooth;
}

</style>

